const contentful = require("contentful");
const parseVideoLink = link => {
  if (!link) {
    return null;
  }
  const type = link.includes("vim") ? "vimeo" : "youtube";
  const parts = link.split("/");
  const id = parts[parts.length - 1];
  return type === "vimeo"
    ? `https://player.vimeo.com/video/${id}?color=f9cbc9&title=0&byline=0&portrait=0`
    : `http://www.youtube.com/embed/${id}`;
};

const mapEvento = item => {
  return {
    ...item.fields,
    fotoMiniatura: item.fields.fotoMiniatura?.fields.file.url,
    fotoPrincipal: item.fields.fotoPrincipal?.fields.file.url,
    fotos: item.fields.fotos?.map(foto => foto.fields.file.url) || [],
    videoPrincipal: parseVideoLink(item.fields.videoPrincipalLink),
    participantes:
      item.fields.participantes?.map(p => ({
        ...p.fields
      })) || []
  };
};

class CMSService {
  constructor() {
    this.client = contentful.createClient({
      space: process.env.VUE_APP_CONTENTFUL_SPACE,
      accessToken: process.env.VUE_APP_CONTENTFUL_TOKEN
    });
  }

  async events(type) {
    const response = await this.client.getEntries({
      content_type: type,
      include: 3
    });

    return response.items.map(mapEvento);
  }

  async details({ slug, type }) {
    const response = await this.client.getEntries({
      content_type: type,
      "fields.nombreUrl": slug,
      include: 3,
      limit: 1
    });

    console.log(response);

    if (!response.items.length > 0) {
      throw new Error("Boda not found");
    }

    return mapEvento(response.items[0]);
  }
}

export default new CMSService();
